import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack,  Box, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { useTheme } from '@emotion/react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import Async from "react-select/async";


// ----------------------------------------------------------------------

export default function AddResultStory() {

    const theme = useTheme();
    const animatedComponents = makeAnimated();
    const[student , setStudent] = useState('');
      const handleChangeValue = (data) => {
          setStudent(data);
        }
        
  
        const callApi  = async (text) => {
          // console.log(text);
          const token = localStorage.getItem("token");
          const fullResponse = await fetch(
                  `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails?text=${text}`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
          );
          const responseJson = await fullResponse.json();
          return responseJson?.data;
                
          // }
          // fetchDetails();
        }
        const promiseOptions = (inputValue) =>{
           return new Promise((resolve) => {
             setTimeout(() => {
              resolve(callApi(inputValue));
               }, 100);
          })
        };
    const [loading, setLoading] = useState(false)
    
    const [list , setList] = useState([]);
    const [category , setCategory] = useState();

    const handleChangeCategory =  (newValue ) => {
      setCategory(newValue);
  }
    const RegisterSchema = Yup.object().shape({
        resultInfo: Yup.string().required('Result Information Required'),
        url: Yup.string()
                .required('YouTube URL is required')
                .matches(
                  /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|shorts\/|live\/)|youtu\.be\/)[a-zA-Z0-9_-]+(\?.*)?$/,
                  'Invalid YouTube URL. Please provide a valid YouTube URL (standard, embedded, shortened, or live).'
                ),
        desc: Yup.string().required('Title required'),
        isActive: Yup.boolean().required('Status Required'),
    });

    const defaultValues = {
        category: '',
        isActive: '',
    };
    

    
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    // console.log("67" , file);
    

    const onSubmit = async (data) => {
            setLoading(true);
            

            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                
            };
            axios.post(`${process.env.REACT_APP_LIVE_URL}/adminSection/createSuccessStory`, {...data , category : category?._id , user : student?.id }, config).then((response) => {
                if (response.data.status) {
                    toast.success(response.data.msg);
                    setLoading(false);
                    reset();
                    window.location.href = "/dashboard/resultStory";
                } else {
                    toast.error(response.data.msg);
                    setLoading(false);
                }

            })
                .catch(e => {
                    //console.log(e);
                })
        

    };

    useEffect(() => {
            const token = localStorage.getItem("token");
            async function fetchDetails() {
                const fullResponse = await fetch(
                    `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategoryListByAdmin`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const responseJson = await fullResponse.json();
                setList(responseJson.data);
            }
            fetchDetails();
        

    }, []);

    return (<>
        <Stack spacing={3} px={5} >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                  <Stack sx={{ width : '100%'}}>
                    <Async 
                      components={animatedComponents}
                      menuPortalTarget={document.body}
                      onChange={handleChangeValue}
                      value= { student}
                       styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) , placeholder: base => ({ ...base, color: 'gray' }),}}
                      loadOptions={promiseOptions}
                       placeholder='Please Select User'
                     />
                   </Stack>
                    <RHFTextField name="desc" label="Description" />
                    <Stack spacing={3} sx={{width : '100%'}} direction='row'>
                      <RHFTextField name="resultInfo" label="Result Information" />
                       <RHFTextField name="url" label="Youtube Url" />
                    </Stack>
                    
                        <ReactSelect
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            value={category}
                            onChange={handleChangeCategory}
                            placeholder='Please Select Category'
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base ,  zIndex: 9999 }) }}
                            isMulti={false}
                            options={list}
                        />
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="isActive" label="Status" type='checkbox' select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        >
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}
