import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack,  MenuItem , InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField  } from '../../../components/hook-form';
import RHFTextEditor from '../../../components/hook-form/RHFTextEditor';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// ----------------------------------------------------------------------

export default function AddChannel() {
  const theme = useTheme();
  const animatedComponents = makeAnimated();
  const [ categories , setCategories] = useState([]);
  const [selectedValues , setSelectedValues] = useState([])
  const handleChangeValues = (data) => {
    setSelectedValues(data);
  }
  const [loading, setLoading] = useState(false)
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false)
  const [val, setVal] = useState(false);
  

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Title required'),
    description: Yup.string().required('Description required'),
    isActive: Yup.boolean().required('Status Required'),
  });

  const defaultValues = {
    name: '', 
    isActive: '',
    description : "" , 
  };
  const [file, setFile] = useState()

  const changeHandler = async (event) => {
    let size = event.target.files[0].size / ( 1024);
        if( size > 150){
            setVal(true);
        }else{
            setFile(event.target.files)
           
        }
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  

  const onSubmit = async (data) => {
   
    if (file) {
      const formData = new FormData();
      setLoading(true);
      Object.values(file).forEach(file => {
        formData.append("file", file);
      })
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('isActive', data.isActive);
      formData.append('categories' , "");
      formData.append('categories' , "");
      for( let value of selectedValues ){
         formData.append('categories' , value?.value );
      }
      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
        
      };
      axios.post(`${process.env.REACT_APP_LIVE_URL}/learning/createChannel`, formData, config).then((response) => {
        if (response.data.status) {
          toast.success(response.data.msg);
          setLoading(false);
          reset();
          window.location.href = "/dashboard/quicklearning/channels";
        }else {
          toast.error(response.data.msg);
          setLoading(false);
        }

      })
        .catch(e => {
          toast.error(e.message)
        })
    }
  };
 
  useEffect(() => {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategoryListByAdmin`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setCategories(responseJson.data);

      }
      fetchDetails();
    
    setLoading(false);

  }, []);
  
  return (<>
    <Stack spacing={3} px={5} >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
        <Stack spacing={3} sx={{ width: '70%' }}>
          <RHFTextField name="name" label="Title" />
          <Stack direction="row" spacing={3}>
            <RHFTextField name="file"
              onChange={changeHandler}
              id="file"
              label="File"
              error={!file && val}
              helperText='Image Only (Maximum 100KB allowed)'
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              InputProps={{
                classes: {
                  input: "CustomTextField"
                }
              }}
              onClick={() => setPdf(true)}
              inputProps={{ accept: "image/*" }}
              type={pdf || focus ? "file" : "text"}
            />
          </Stack>
          <RHFTextEditor name="description" label="Description" />
          <Stack direction='row' spacing={2}>
              <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Categories - </InputLabel>
             <Stack direction='column' sx={{ width : '70%'}}>
                <Select
                  closeMenuOnSelect={false}
                  placeHolder="Select Category"
                  components={animatedComponents}
                  value={selectedValues}
                  onChange={handleChangeValues}
                  menuPortalTarget={document.body} 
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isMulti={true}
                  options={categories}
                />
              </Stack>
          </Stack>
          <Stack direction="row" spacing={3}>
            <RHFTextField name="isActive" label="Status" type='checkbox' select >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFTextField>
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
            sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
            onClick={() => {
              setVal(true)
            }}>
            Add
          </LoadingButton>
        </Stack>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
     
    </Stack>
  </>
  );
}
