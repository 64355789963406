import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack,  MenuItem , InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField  } from '../../../components/hook-form';
import RHFTextEditor from '../../../components/hook-form/RHFTextEditor';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// ----------------------------------------------------------------------

export default function AddShort() {
  const theme = useTheme();
  const { channelId } = useParams();
  const animatedComponents = makeAnimated();
  
  const [loading, setLoading] = useState(false)
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false)
  const [val, setVal] = useState(false);
  

  const RegisterSchema = Yup.object().shape({
    title : Yup.string().required('Title required'),
    description: Yup.string().required('Description required'),
    isActive: Yup.boolean().required('Status Required'),
  });

  const defaultValues = {
    title : '', 
    isActive: '',
    description : "" , 
  };
  const [file, setFile] = useState()

  const changeHandler = async (event) => {
    setFile(event.target.files[0])
    // let size = event.target.files[0].size / ( 1024);
        // if( size > 150){
        //     setVal(true);
        // }else{
        //     setFile(event.target.files)
           
        // }
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  

  const onSubmit = async (data) => {
   
    if (file) {
      const formData = new FormData();
      setLoading(true);
     
      formData.append("file", file);

      formData.append('title', data.title);
      formData.append('channel' , channelId);
      formData.append('description', data.description);
      formData.append('isActive', data.isActive);
      
      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
        
      };
      axios.post(`${process.env.REACT_APP_LIVE_URL}/learning/uploadShortVideo`, formData, config).then((response) => {
        if (response.data.status) {
          toast.success(response.data.msg);
          setLoading(false);
          reset();
          window.location.href = `/dashboard/quicklearning/channel/${channelId}`;
        }else {
          toast.error(response.data.msg);
          setLoading(false);
        }

      })
        .catch(e => {
          toast.error(e.message)
        })
    }
  };
 
  
  return (<>
    <Stack spacing={3} px={5} >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
        <Stack spacing={3} sx={{ width: '70%' }}>
          <RHFTextField name="title" label="Title" />
          <Stack direction="row" spacing={3}>
            <RHFTextField name="file"
              onChange={changeHandler}
              id="file"
              label="File"
              error={!file && val}
              helperText='Videos Only'
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              InputProps={{
                classes: {
                  input: "CustomTextField"
                }
              }}
              onClick={() => setPdf(true)}
              inputProps={{ accept: "video/*" }}
              type={pdf || focus ? "file" : "text"}
            />
          </Stack>
          <RHFTextField name="description" label="Description" />
          
          <Stack direction="row" spacing={3}>
            <RHFTextField name="isActive" label="Status" type='checkbox' select >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFTextField>
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
            sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
            onClick={() => {
              setVal(true)
            }}>
            Add
          </LoadingButton>
        </Stack>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
     
    </Stack>
  </>
  );
}
