import * as Yup from "yup";
import { useState, useEffect } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// ------------------------------------------------------------------
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import ReactSelect from 'react-select';
import Async from "react-select/async";
import makeAnimated from 'react-select/animated';

export default function UpdateCoupon() {

const theme=useTheme();
const animatedComponents = makeAnimated();

  const {  couponId , from }= useParams();
  const [loading, setLoading] = useState(false);
  const [starting, setStarting] = useState(dayjs());
  // link
  const[type , setType] = useState("none");
  const[list , setList] = useState([]);
  const[valueId ,setValueId] = useState("");

  
  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  

 const [values , setValues] = useState({});

 const [couponAccess , setCouponAccess] = useState('');
 
  const handleChangeAcess = (e) =>{
    // console.log(e.target.value);
    setCouponAccess(e.target.value);
    if( e.target.value == 'all'){
      setStudent(null);
    }
    // setStudent();
  }

  const[student , setStudent] = useState('');
      const handleChangeValue = (data) => {
          setStudent(data);
        }
        
  
        const callApi  = async (text) => {
          const token = localStorage.getItem("token");
          const fullResponse = await fetch(
                  `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails?text=${text}`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
          );
          const responseJson = await fullResponse.json();
          return responseJson?.data;
        }
        const promiseOptions = (inputValue) =>{
           return new Promise((resolve) => {
             setTimeout(() => {
              resolve(callApi(inputValue));
               }, 100);
          })
        };

  useEffect(() => {
        
    const token = localStorage.getItem("token");
    async function fetchDetails() {
         const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCouponById/${couponId}`,
                {
                 method: "GET",
                 headers: {
                    "Content-Type": "application/json",
                     Authorization: `Bearer ${token}`,
                },
           }
      );
      const responseJson = await fullResponse.json();
      checkDetails(responseJson.data);
    }

fetchDetails();

setLoading(false);

}, []);

const checkDetails = (couponDetails) => {
  setValues( prev => ({
      ...prev,
      couponAccess : couponDetails?.couponAccess,
      couponCode : couponDetails?.couponCode,
      couponType : couponDetails?.couponType,
      couponValue : couponDetails?.couponValue,
      is_active: couponDetails?.is_active,
      expirationDate : couponDetails?.expirationDate,
      valueId: couponDetails?.linkWith,
      link : couponDetails?.link,
      linkWith : couponDetails?.linkWith , 
      linkWiths :  couponDetails?.linkWiths , 
      student :  couponDetails?.student, 
      count :  couponDetails?.count , 

  }));
  setCouponAccess(couponDetails?.couponAccess);
  setType(couponDetails?.link);
  setStarting(dayjs(couponDetails?.expirationDate , 'YYYY-MM-DDTHH:mm:ssZ') );
  setValueId(couponDetails?.linkWith);
  setStudent(couponDetails?.student);
}
  useEffect(() => {
    if( type !== '' && type !=='none'){

      let urlVariable = ""
      if( type == 'batch'){
        urlVariable = "adminPanel/getBatches"
      }else if( type == 'category'){
        urlVariable = "adminPanel/getCategoryListByAdmin"
      }else if( type == 'testSeries'){
        urlVariable = "adminPanel/getallTestSeriesdetails"
      }else if( type == 'product'){
        urlVariable = "store/getAllStoreProduct"
      }else{
        urlVariable = "store/getAllStoreProductCategory"
      }
        const token = localStorage.getItem("token");
    async function fetchDetails() {
        const fullResponse = await fetch(
            `${process.env.REACT_APP_LIVE_URL}/${urlVariable}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const responseJson = await fullResponse.json();
          setList(responseJson.data);
          // console.log(responseJson.data);
          // console.log(values?.linkWiths)
          setSelectedList(responseJson.data?.filter((item) => values?.linkWiths?.some((item2) => item?.value == item2)))
          
    }
    fetchDetails();
    }
    setLoading(false);
    
}, [type]);

const onChange2 = (e) => {
  const { name, value } = e.target;
  setValues(prev => ({ ...prev, [name]: value }))
  if( name == 'link' && value == 'none'){
      setType(value);
      // setValueId("");
      // setId("");
      defaultValues.linkWith = "";
      // console.log(defaultValues);
      setValues( prev => ({ ...prev , ['valueId'] : ""}))
  }
  if( name == 'link' && value != 'none'){
      setType(value);
      setValues( prev => ({ ...prev , ['valueId'] : ""}))
  }
  // debugger;
  if( name == 'valueId' && (value != '' || typeof(value) != 'undefined' )){
      setValues( prev => ({ ...prev , ['valueId'] : value}))
      
  }
  

}

   const [ selectedList , setSelectedList] = useState([]);
   const handleSelectedList = (data) => {
     setSelectedList(data);
   }
  const RegisterSchema = Yup.object().shape({});
  const defaultValues = {
    couponCode: values.couponCode,
    couponType: values.couponType,
    couponValue: values.couponValue,
    link: values.link,
    valueId:valueId,
    couponAccess: values.couponAccess,
    is_active: values.is_active,
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = async (data) => {
    setLoading(true)
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const reqObject = {
      couponCode: values.couponCode,
      couponType: values.couponType,
       couponValue: values.couponValue,
      link: values.link,
      linkWith : valueId,
      couponAccess: couponAccess,
      is_active: values.is_active,
      expirationDate: starting?.$d,
    selectedList, 
      student : couponAccess == 'all' ? null : student?.value , 
      count : couponAccess == 'all' ? 0 : values?.count  , 
    };
    // console.log(reqObject);
    axios
      .put(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/editCoupon/${couponId}`,
        reqObject,
        config
      )
      .then((response) => {
        if (response.data.status == true) {
          toast.success(response.data.msg);
          
          setLoading(false);
          reset();
        
          if( from == 'allCoupon'){
            window.location.href = '/dashboard/allCoupon';
          }else{
            window.location.href = '/dashboard/storeCoupon';
          }
        }else{
          toast.error(response.data.msg);
          setLoading(false)
        }
      })
      .catch((e) => {
        // console.log(e);
      });
  };
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Update Coupon- <strong style={{color:'blue'}}>{values?.couponCode}</strong></Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="couponCode"  value={values?.couponCode} onChange={onChange2}/>
              <RHFTextField name="couponType" label={values?.couponType == 'percentage' ? "Percentage" : "Fixed"} value={values?.couponType} onChange={onChange2}select>
                <MenuItem value="percentage">Percentage (%)</MenuItem>
                <MenuItem value="fixed">Fixed</MenuItem>
              </RHFTextField>
            </Stack>
            <Stack direction="row" spacing={3}>
                        { from != 'store' ? <RHFTextField name="link" value={type} type='checkbox' onChange={onChange2} select >
                            <MenuItem value='none' onClick={() => setType('none')}>None</MenuItem>
                            <MenuItem value='batch' onClick={() => setType('batch')}>Batch</MenuItem>
                             <MenuItem value='category' onClick={() => setType('category')}>Batch Category</MenuItem>
                            <MenuItem value='testSeries' onClick={() => setType('testSeries')}>Test Series</MenuItem>
                            <MenuItem value='product' onClick={() => setType('product')}>Product</MenuItem>
                            <MenuItem value='productCategory' onClick={() => setType('productCategory')}>Product Category</MenuItem>
                        </RHFTextField>  : 
                            <RHFTextField name="link" value={type} type='checkbox' onChange={onChange2} select >
                                <MenuItem value='none' onClick={() => setType('none')}>None</MenuItem>
                               <MenuItem value='product' onClick={() => setType('product')}>Product</MenuItem>
                                <MenuItem value='productCategory' onClick={() => setType('productCategory')}>Product Category</MenuItem>
                           </RHFTextField> 
                        }
                       { values?.link  !== 'none' && <Stack sx={{ width : '100%'}}>
                         <ReactSelect
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            value={selectedList}
                            onChange={handleSelectedList}
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isOptionDisabled={(option) => {
                              if (selectedList.some((sel) => sel.value === 'all')) {
                                return option.value !== 'all'; 
                              }
                              return selectedList.length > 0 && option.value === 'all';
                            }}
                            isMulti
                            options={ type == 'product' ? [{ label : 'All' , value : 'all' , _id : 'all' , id : 'all'} , ...list ] : list}
                          />
                        </Stack>}
                        
                        
                    </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="couponValue" value={values.couponValue} onChange={onChange2}/>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  direction="row"
                  spacing={3}
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <DateTimePicker
                    label="Expire At"
                    value={starting.$d}
                    // value={values.expirationDate}
                    onChange={handleChange}
                    // name="starting_date"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField label={values?.couponAccess == 'all' ? "All" : "Single"} name="couponAccess" value={couponAccess}  select onChange={handleChangeAcess}>
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="single">Single</MenuItem>
              </RHFTextField>
              <RHFTextField name="is_active" label={values?.is_active ? 'Active' : 'Inactive'} value={values?.is_active} onChange={onChange2} select>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>
            {couponAccess == 'single' && <Stack direction="row" spacing={3}>
                <Stack sx={{ width : '100%'}}>
                  <Async 
                   components={animatedComponents}
                   menuPortalTarget={document.body}
                   onChange={handleChangeValue}
                   value= { student}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) , placeholder: base => ({ ...base, color: 'gray' }),}}
                    loadOptions={promiseOptions}
                    placeholder='Please Select User'
                   />
                </Stack>
                   <RHFTextField name="count" value={values.count} label={"Count for Student" } onChange={onChange2}/>
            </Stack>}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
            >
              Update Coupon
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
