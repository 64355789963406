import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridToolbar,
    GridActionsCellItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridToolbarDensitySelector,
    useGridApiContext,
    gridExpandedSortedRowIdsSelector,
    GridRowEditStopReasons
  } from "@mui/x-data-grid";
  import IconButton from '@mui/material/IconButton';
  import Tooltip from '@mui/material/Tooltip';
  import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";

function CustomToolbar ({rows , columns }){
    const apiRef = useGridApiContext();
    const exportAllRows = async () => {
       const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
      const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
      const columnIds = columns.filter((column) => column.field != 'actions').map((column) => column.field);
      const csvHeader = columnIds.join(', ');
      const csvContent = filteredRows
        .map((row ,index) => {
          const rowValues = columnIds.map((columnId) => {
            if (columnId === 'admin') {
              return `${row.admin.name}(${row.admin.role})`;
            }
            else if (columnId === 'linkWith') {
              return row.linkWith.title
            }
            else if ( columnId === 'serialNumber'){
              return index+1
            }
            return row[columnId]?.toString()?.replaceAll(",","");
          });
          return rowValues.join(', ');
        })
        .join('\n');
      const fullCsvContent = `${csvHeader}\n${csvContent}`;
      const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'StoreCoupon.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
         
        <GridToolbarDensitySelector />
        <Tooltip title="Export All">
          <IconButton onClick={exportAllRows}>
            <SaveAltIcon style={{color : 'red'}}/>
          </IconButton>
        </Tooltip>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };
  


export default function StoreCouponTable() {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [rowModesModel, setRowModesModel] = React.useState({});
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(true);
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCouponsForPanel?type=store`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setRows(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    // console.log(id);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this coupon?"
    );
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/deleteCoupon/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson.status) {
        toast.success(resJson.msg);
        setRows(rows.filter((row) => row.id !== id));
      } else {
        toast.error(resJson.msg);
      }
    }
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));
  const access = getAccess();

  const columns = [
    {
      field: 'serialNumber',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      // editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },

      {
        field: "couponCode",
        headerName: "Coupon Code",
        type: "string",
        width: 300,
        align: "left",
        headerAlign: "left",
        // editable: true,
      
      },
      {
        field: "couponType",
        headerName: "Coupon Type",
        type: "object",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true,
        
      },
      {
        field: "couponValue",
        headerName: "Coupon Value",
        type: "string",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true,
      
      },
      {
        field: "expirationDate",
        headerName: "Expire At",
        type: "string",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true,
        
      },
      {
        field: "link",
        headerName: "Link",
        type: "object",
        width: 150,
        align: "left",
        headerAlign: "left",
        // editable: true,
        renderCell: (params) => (
            <div>{params.value == 'batch'? 'Batch' : params.value == 'category'? 'Category' :params.value == 'testSeries' ? 'Test Series' :  params.value == 'product' ? "Product" : params.value == 'productCategory' ? "Product Category" : "NA"}</div>
          ),
      },
      {
        field: "linkWiths",
        headerName: "Link Withs",
        type: "string",
        width: 200,
        align: "left",
        headerAlign: "left",
      },
      {
        field: "linkWith",
        headerName: "Link With",
        type: "object",
        width: 190,
        align: "left",
        headerAlign: "left",
        // editable: true,
        renderCell: (params) => (
            <div>{params.value.title ? params.value.title : "NA"}</div>
          ),
      },
      {
        field: "couponAccess",
        headerName: "Access",
        type: "string",
        width: 150,
        align: "left",
        headerAlign: "left",
        renderCell: (params) => (
          <div>{params.value !== 'all' ? `User Specific` : "All"}</div>
        ),
        // editable: true,
      },
      {
        field: "student",
        headerName: "Student",
        type: "object",
        width: 190,
        align: "left",
        headerAlign: "left",
        // editable: true,
        renderCell: (params) => (
            <div>{params.value.name !== 'NA' ? `${params.value.name}(${params.value.phone})` : "NA"}</div>
          ),
      },
      {
        field: "count",
        headerName: "Limit",
        type: "string",
        width: 100,
        align: "left",
        headerAlign: "left",
        // editable: true,
      },

      {
        field: "is_active",
        headerName: "Status",
        type: "boolean",
        width: 100,
        align: "left",
        headerAlign: "left",
        // editable: true,
        renderCell: (params) => (
            <div style={{ color: params.value == true ? 'green' : 'red' }}>
              {params.value ? 'Active' : 'inActive'}
            </div>
          ),
      
      },
      {
        field: "created_at",
        headerName: "Created At",
        type: "string",
        width: 200,
        align: "left",
        headerAlign: "left",
        // editable: true,
        // renderCell: (params) => (
        //     <div>
        //       {parama}
        //     </div>
        //   ),
      
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main"
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            ];
          }
  
          return [
            ((access?.access?.includes('all') || access?.accessForTab?.includes('readWrite')) ? <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => navigate(`/dashboard/coupon/updateCouponDetails/${id}/${'store'}`)}
              color="inherit"
            /> : <></>),
          ];
        }
      }
]
  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      {/* <h3 style={{ marginBottom: "6px" }}>All Store Coupons</h3> */}
      <DataGrid
        rows={rowsWithSerialNumber}
        columns={columns}
        getRowHeight={() => 'auto'}
        editMode="row"
        autoHeight={true}
        loading={loading}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        // slots={{
        //   //   toolbar: EditToolbar,
        //   toolbar: GridToolbar,
        // }}
        slots={{
            toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
          }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
