import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, MenuItem,  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import { RHFTextField , FormProvider } from '../../../../components/hook-form';
import { useParams } from 'react-router-dom';


// ----------------------------------------------------------------------

export default function AddValidityFeature() {
    const theme= useTheme();
    const { batchId } =  useParams();
    const [loading, setLoading] = useState(false);
    


    const RegisterSchema = Yup.object().shape({
        name: Yup.string().required('Feature required'),
        isActive: Yup.boolean().required('Status required'),
    });

    

    const defaultValues = {
        name: '',
        info: '',
        batchId : "" , 
        isActive: false ,
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        
            setLoading(true)
            let token = localStorage.getItem("token");
            const config = {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                
            };
            try {
                axios.post(`${process.env.REACT_APP_LIVE_URL}/adminSection/createValidityFeature`, {...data , batchId }, config).then((response) => {
                    if (response.data.status) {
                        toast.success(response.data.msg);
                        setLoading(false);
                        
                       reset();
                       window.location.href = `/dashboard/validityFeaturesOfBatches/${batchId}`;
                    }else{
                        toast.error(response.data.msg);
                        setLoading(false);
                    }
    
                })
                    .catch(e => {
                      toast.error(e.message);

                    })
                
            } catch (error) {
                  toast.error(error.message);
            }
        
    };
    return (<>
        <Stack spacing={3} px={5} >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField name="name" label="Name"  />
                    <RHFTextField name="info" label="Infomation"  />
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="isActive" label="Status" type='checkbox' select>
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        // onClick={() => {
                        //     setVal(true)
                        //     setMsg('Required')}}
                        >
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Stack >
    </>
    );
}
