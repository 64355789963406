import * as Yup from "yup";
import { useState, useEffect } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem, TextField ,Select , InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// ------------------------------------------------------------------
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
import ReactSelect from 'react-select';
import Async from "react-select/async";

import makeAnimated from 'react-select/animated';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,

        },
    },
};
export default function AddCoupon() {

  const theme = useTheme()
  const animatedComponents = makeAnimated();
  const [loading, setLoading] = useState(false);
  const [starting, setStarting] = useState(dayjs());
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  // link
  const [type, setType] = useState("none");
  const [list, setList] = useState([]);
  const [ selectedList , setSelectedList] = useState([]);

  const [couponAccess , setCouponAccess] = useState('');
  const handleChangeAcess = (e) =>{
    setCouponAccess(e.target.value);
    setStudent();
  }

  const[student , setStudent] = useState('');
      const handleChangeValue = (data) => {
          setStudent(data);
        }
        
  
        const callApi  = async (text) => {
          // console.log(text);
          const token = localStorage.getItem("token");
          const fullResponse = await fetch(
                  `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails?text=${text}`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
          );
          const responseJson = await fullResponse.json();
          return responseJson?.data;
                
          // }
          // fetchDetails();
        }
        const promiseOptions = (inputValue) =>{
           return new Promise((resolve) => {
             setTimeout(() => {
              resolve(callApi(inputValue));
               }, 100);
          })
        };
  const RegisterSchema = Yup.object().shape({
    couponCode: Yup.string().required("couponCode required"),
    couponType: Yup.string().required("couponType is required"),
    couponValue: Yup.number().required("couponValue should be number"),
    link: Yup.string()
      .required('Link Coupon Required')
      .oneOf(['none', 'batch', 'category','testSeries', 'product', 'productCategory'], 'Invalid Link Value'),
    // valueId: Yup.string().when('link', {
    //   is: (link) => link !== 'none' && link !== '',
    //   then: Yup.string().required('Link With Required'),
    //   otherwise: Yup.string().notRequired(),
    // }),
    // couponAccess: Yup.string().required("couponAccess is required"),
    is_active: Yup.boolean().required("Status Required"),
  });
  const defaultValues = {
    couponCode: "",
    couponType: "",
    couponValue: 0,
    link: "",
    valueId: "",
    couponAccess: "",
    user : null ,
    count : 0 , 
    is_active: "",
  };
  const handleChange = (newValue) => {
    setStarting(newValue);
  };

  const handleSelectedList = (data) => {
     setSelectedList(data);
  }
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true)
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const reqObject = {
      ...data,
      // linkWith: data.valueId,
      selectedList ,
      student :  student?.value ?? null  , 
      count : couponAccess == 'single' ? data?.count : 0  , 
      couponAccess :  couponAccess,
      // expirationDate: starting?.$d,
      expirationDate: starting?.add(5, "hour").add(30, "minute"),

    };
    // console.log(selectedList);
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/addCoupon`,
        reqObject,
        config
      )
      .then((response) => {
        // debugger;
        // console.log(response.data);
        if (response.data.msg === "Coupons Details Added Successfully") {
          toast.success(response.data.msg);
          setVal(false);
          setMsg("");
          setLoading(false);
          setSelectedList([]);
          reset();
          // setCouponAccess('all');
          setStudent();
          window.location.href = "/dashboard/allCoupon";
        } else {
          toast.error(response.data.msg);
          setLoading(false)
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (type !== '' && type !== 'none') {

      let urlVariable = ""
      if (type == 'batch') {
        urlVariable = "adminPanel/getBatches"
      }else if (type == 'category') {
        urlVariable = "adminPanel/getCategoryListByAdmin"
      }  
      else if (type == 'testSeries') {
        urlVariable = "adminPanel/getallTestSeriesdetails"
      } else if (type == 'product') {
        urlVariable = "store/getAllStoreProduct"
      } else {
        urlVariable = "store/getAllStoreProductCategory"
      }
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/${urlVariable}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson.data);

      }
      fetchDetails();
    }
    setLoading(false);
    setSelectedList([]);

  }, [type]);

  //console.log(starting.$d);
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Coupon </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="couponCode" label="Coupon Code" />
              <RHFTextField name="couponType" label="Coupon Type" select>
                <MenuItem value="percentage">Percentage (%)</MenuItem>
                <MenuItem value="fixed">Fixed</MenuItem>
              </RHFTextField>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="link" label="Link Coupon" type='checkbox' select >
                <MenuItem value='none' onClick={() => setType('none')}>None</MenuItem>
                <MenuItem value='batch' onClick={() => setType('batch')}>Batch</MenuItem>
                <MenuItem value='category' onClick={() => setType('category')}>Batch Category</MenuItem>

                <MenuItem value='testSeries' onClick={() => setType('testSeries')}>Test Series</MenuItem>
                <MenuItem value='product' onClick={() => setType('product')}>Product</MenuItem>
                <MenuItem value='productCategory' onClick={() => setType('productCategory')}>Product Category</MenuItem>
                
              </RHFTextField>
              
            </Stack>
           { type != 'none' && <Stack direction="row">
                            <InputLabel sx={{ width: '35%', marginRight: '20px', alignSelf: 'center' }}>Select {type == 'batch' ? "Batches" : type =="testSeries" ? "TestSeries" : type=="product" ? "Products" : "Product Catgeory"}</InputLabel>
                            <Stack direction='column' sx={{ width: '100%' }}>
                            <ReactSelect
                                         closeMenuOnSelect={false}
                                         components={animatedComponents}
                                         value={selectedList}
                                         onChange={handleSelectedList}
                                         menuPortalTarget={document.body} 
                                         styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        //  defaultValue={[colourOptions[4], colourOptions[5]]}
                                         isMulti
                                        //  options={list},
                                        isOptionDisabled={(option) => {
                                          if (selectedList.some((sel) => sel.value === 'all')) {
                                            return option.value !== 'all'; 
                                          }
                                          return selectedList.length > 0 && option.value === 'all';
                                        }}
                                        // isOptionDisabled={(option) => selectedList.some((selected) => selected.value === option.value)}
                                        options={ type == 'product' ||  type == 'batch'  ? [{ label : 'All' , value : 'all' , _id : 'all' , id : 'all'} , ...list ] : list}

                                       />

                                
                                  {selectedList.length == 0 && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}
                            </Stack>
            </Stack> }
            <Stack direction="row" spacing={3}>
              <RHFTextField name="couponValue" label="Coupon Value" />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  direction="row"
                  spacing={3}
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <DateTimePicker
                    label="Expire At"
                    value={starting.$d}
                    onChange={handleChange}
                    // name="starting_date"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="couponAccess" value={couponAccess} label="Coupon Access" select onChange={handleChangeAcess}>
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="single">Single</MenuItem>
              </RHFTextField>
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>
            {couponAccess == 'single' && <Stack direction="row" spacing={3}>
                <Stack sx={{ width : '100%'}}>
                  <Async 
                   components={animatedComponents}
                   menuPortalTarget={document.body}
                   onChange={handleChangeValue}
                   value= { student}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) , placeholder: base => ({ ...base, color: 'gray' }),}}
                    loadOptions={promiseOptions}
                    placeholder='Please Select User'
                   />
                </Stack>
                   <RHFTextField name="count" label="Count for Student" />
            </Stack>}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
