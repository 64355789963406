import * as React from "react";
import Box from "@mui/material/Box";
import CommentIcon from '@mui/icons-material/Comment';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import EditIcon from '@mui/icons-material/Edit';
import {
  Tooltip,
} from "@mui/material";

import { ToastContainer, toast } from 'react-toastify';
import { Stack , Avatar,Typography} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import getAccess from "../../../../utils/getAccess";

function CustomToolbar({ rows, columns, ...gridProps }) {
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field != 'actions').map((column) =>  column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row, index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'admin') {
            return `${row?.user?.name}`;
          }
          return row[columnId]?.toString()?.replaceAll(",", " ");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'ValidityFeature.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

const ValidityFeatureTable =() => {
  const navigate = useNavigate();
  const { batchId} = useParams();
  const access = getAccess();
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [loading , setLoading] = React.useState(true);
  React.useEffect(()=>{
    const token = localStorage.getItem('token');
    async function fetchFeatures(){
      const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminSection/getValidityFeatures?batchId=${batchId}` , {
        method : 'GET',
        headers : {
          'Content-Type':'application/json',
          Authorization:`Bearer ${token}`

      }
      });
      const responseJson = await response.json();
       setRows(responseJson.data ?? []);
       setLoading(false);
    }
    fetchFeatures()
  },[])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  
    const makeActiveAndInActive= async (id) => {
      const token = localStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminSection/makeActiveAndInActiveValidtyFeature/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
  
      });
      const responseJson = await response.json();
      if( responseJson?.status){
        toast.success(responseJson.msg)
        setTimeout(() => window.location.reload()
        , 1000)
      }else{
        toast.error(responseJson.msg);
      }
      
  
    }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  

  const columns = [
    {
      field: 'sno',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },
    {
      field: "admin",
      headerName: "Admin",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
      renderCell : (params) =>  (
        <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={params?.value?.name} src={params?.value?.profilePhoto} sx={{ width: 28, height: 28 }} />
            <Typography variant="subtitle2" noWrap>{params?.value?.name}</Typography>
        </Stack>
      )
      
    },
    {
      field: "name",
      headerName: "Feature Name",
      type: "string",
      width: 140,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "info",
      headerName: "Info",
      type: "string",
      width: 270,
      align: "left",
      headerAlign: "left",
    },
      
      {
        field: "isActive",
        headerName: "Status",
        type: "boolean",
        width: 100,
        align: "left",
        headerAlign: "left",
        renderCell: (params) => (
            <div style={{ color: params.value == true ? 'green' : 'red' }}>
              {params.value ? 'Active' : 'In Active'}
            </div>
          ),
      
      },
      {
        field: "createdAt",
        headerName: "Created At",
        type: "Date",
        width: 150,
        align: "left",
        headerAlign: "left",
        
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          const rowData = rows.find((item) => item.id === id);
          
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main"
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            ];
          }
  
          return [
            
            (((access?.accessForTab == 'read' || access?.accessForTab == 'readWrite' ) || access?.access.includes('all') ) ? 
            <Tooltip title={`${rowData?.isActive == true ? 'Make In Active' : 'Make In Active'}`}>
              <GridActionsCellItem
                icon={ rowData?.isActive  == true ? <ToggleOffIcon style={{ color:'green'}}  /> : <ToggleOnIcon style={{color : 'red'}}/>}
                label="Change Status"
                onClick={() => makeActiveAndInActive(id)}
                color="inherit"
              />
            </Tooltip> : <></>),
            
            
          ];
        }
      }
]

  

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        loading={loading}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25,50]}
        
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel , showQuickFilter: true,}
        }}
      />
                          <ToastContainer
                            position="bottom-center"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                          />
    </Box>
  );
}
export default ValidityFeatureTable
